import React from "react"
import { Link } from "gatsby"
import { Wrapper, Container } from "./ServArea.styles"

const ServArea = () => {
  return (
    <Wrapper>
      <Container>
        <div class="card">
          <div class="face face1">
            <div class="content">
              <span class="stars"></span>

              <Link to="/portafolio/branding/">
                <h2 class="design">Diseño digital e impreso:</h2>

                <br></br>
                <p class="design">
                  Somos expertos en impresiónes de gran formato y nos
                  especializamos en diseño de gráficos 2D y 3D.
                </p>
              </Link>
            </div>
          </div>
          <div class="face face2">
            <h2>DISEÑO GRÁFICO</h2>
          </div>
        </div>

        <div class="card">
          <div class="face face1">
            <div class="content">
              <span class="stars"></span>
              <Link to="/portafolio/all-proyects/">
                <h2 class="social">Administración de cuentas y campañas:</h2>

                <br></br>
                <p class="social">
                  Tenemos la 'chispa' que tus redes necesitan para capturar una
                  audiencia fiel y orgánica.
                </p>
              </Link>
            </div>
          </div>
          <div class="face face2">
            <h2>REDES SOCIALES</h2>
          </div>
        </div>

        <div class="card">
          <div class="face face1">
            <div class="content">
              <span class="stars"></span>
              <Link to="/portafolio/video/">
                <h2 class="creative">Creación / Edición de contenido:</h2>

                <br></br>
                <p class="creative">
                  Confíanos tu proyecto de fotografía o video, deja a nuestro
                  lente: ¡Hacer arte!
                </p>
              </Link>
            </div>
          </div>
          <div class="face face2">
            <h2>FOTOGRAFÍA Y VIDEO</h2>
          </div>
        </div>

        <div class="card">
          <div class="face face1">
            <div class="content">
              <span class="stars"></span>
              <Link to="/portafolio/video/">
                <h2 class="responsive">Diseño Responsivo.</h2>

                <br></br>
                <p class="responsive">
                  ¡El futuro es ahora! Descubre las ventajas de tener una
                  presencia adecuada en dispositivos móviles.
                </p>
              </Link>
            </div>
          </div>
          <div class="face face2">
            <h2>Web y Mobile</h2>
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}

export default ServArea
