import React from "react"
import { useAboutQuery } from "../../hooks/useAboutQuery"
import { Wrapper, AboutImage } from "./About.styles"

const About = () => {
  const data = useAboutQuery()
  const imageData = data.wpPage.featuredImage.node.localFile.publicURL

  return (
    <Wrapper>
      <div className="about-text">
        <div
          class="about-text__full"
          dangerouslySetInnerHTML={{ __html: data.wpPage.content }}
        />
        <div class="about-text__excerpt">
          <h1>ADN</h1>
          <p>
            Reinventamos el DISEÑO con ideas claras y novedosas. Somos expertos
            en Aplicaciones Web, Branding, Creación y Edición de Contenido
            Multimedia, Impresión Digital y Modelado 3D.
          </p>
          <p>Más que un recurso, somos un socio creativo y tu mejor amigo…</p>
        </div>
      </div>
      <AboutImage image={imageData} alte="About Image" />
    </Wrapper>
  )
}

export default About
