import styled from "styled-components"

export const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 30px;

  #next {
    margin-right: 10px;
  }

  #next,
  #prev {
    ::before {
      font-size: 30px;
    }
  }
`
