import styled from "styled-components"

export const Wrapper = styled.div`
  width: min(95%, 120rem);
  margin: 1rem auto;
  color: #ffffff;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  padding: 0 25px;

  @media (min-width:768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
      position: relative;
      max-width:70rem;
      height: 20rem;
      background: #000;
      border-radius: 15px;
      box-shadow: 11px 11px 8px #040303, -11px -11px 8px #0e0b0b;

      @media (min-width:450px) {
        height: 18rem;
      }

      @media (min-width:768px) {
        height: 17rem;
      }

      @media (min-width:1024px) {
        height:15rem;
      }

      @media (min-width:1600px) {
        height:14rem;
      }

      .face {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.face1 {
          box-sizing: border-box;
          padding: 20px;

          h2 {
            margin: 0;
            padding: 0;
          }

          .design:hover{
             background-color: #60c1aa;
             opacity:1;
          }

          .design {
            background-color: #60c1aa;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            opacity:0.7;

          }

          .social:hover{
             background-color: #60c1aa;
             opacity:1;
          }

          .social {
            background-color: #60c1aa;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            opacity:0.7;
          }

          .creative:hover{
             background-color: #60c1aa;
             opacity:1;
          }

          .creative {
            background-color: #60c1aa;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            opacity:0.7;
          }

          .responsive:hover{
            background-color: #60c1aa;
            opacity:1;
          }

          .responsive {
            background-color: #60c1aa;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            opacity:0.7;
          }
        }

        &.face2 {
          transition: 0.5s;
          padding: 0 1.5rem;
          h2 {
            font-family: "Gobold", sans-serif;
            text-align:center;
            margin: 0;
            padding: 0;
            font-size: 2.5em;
            color: #fff;
            transition: 0.5s;
            text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            z-index: 10;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
            line-height: calc(100% + 10px);
            text-transform: uppercase;
          }
        }
      }
    }

    .card:hover .face.face2 {
      height: 60px;

      h2 {
        font-family: "Gobold", sans-serif;
        font-size: 1.6em;
      }
    }

    .card:hover{
        box-shadow: 11px 11px 8px #0e0b0b, -11px -11px 8px  #040303;
    }

    .card:nth-child(1) .face.face2 {
      background-image: linear-gradient(
        90deg,
        #000000 10%,
        #0a0a0a 55%,
        #1c1c1c 100%
      );
      border-radius: 15px;
    }

    .card:nth-child(2) .face.face2 {
      background-image: linear-gradient(
        90deg,
        #1c1c1c 0%,
        #0a0a0a 45%,
        #000000 90%
      );
      border-radius: 15px;
    }

    .card:nth-child(3) .face.face2 {
      background-image: linear-gradient(
        90deg,
        #000000 10%,
        #0a0a0a 55%,
        #1c1c1c 100%
      );
      border-radius: 15px;
    }

    .card:nth-child(4) .face.face2 {
      background-image: linear-gradient(
        90deg,
        #1c1c1c 0%,
        #0a0a0a 45%,
        #000000 90%
      );
      border-radius: 15px;
    }
  }
}

`
