import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  img {
    transition: all 0.3s !important;
  }
`

export const CTAImage = styled.div`
  max-height: 600px;
  position: relative;
  overflow: hidden;
  margin: 0 5px;
  padding: 20px;

  img {
    border-radius: 9px !important;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  :hover img {
    transform: scale(1.1);
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    border-radius: 9px !important;
  }

  @media (min-width: 768px) {
    max-height: 800px;
  }
`

export const CTAImageTextWrapper = styled.div`
  position: absolute;
  color: #fff;
  top: 0;
  right: 0.5%;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;

  @media (min-width: 768px) {
    right: 1%;
  }
`

export const CTAImageText = styled.p`
  font-family: "Gobold", sans-serif;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  line-height: calc(100% + 10px);
  text-transform: uppercase;
  margin: 0px;
  text-align: center;
  font-size: 2em;

  :hover {
    transform: scale(1.1);
  }

  @media (min-width: 768px) {
    font-size: 2.1em;
  }

  @media (min-width: 992px) {
    font-size: 2.3em;
  }

  @media (min-width: 1200px) {
    font-size: 2.5em;
  }

  @media (min-width: 1800px) {
    font-size: 3.1em;
  }
`
