import styled from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: #090707;
  width: 100%;

  @media screen and (min-width: 1080px) {
    grid-template-columns: 1fr 1fr;
    text-align: left;
    padding: 50px;
  }

  .about-text {
    padding: 40px 20px 20px 40px;
    font-family: "Chub Gotic", sans-serif;

    h1 {
      font-weight: 400;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.7rem;


      @media (max-width: 768px) {
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
      }

      @media (min-width: 1024px) {
        text-align: left;
        direction: ltr;
        word-break: break-word;
      }
    }

    &__full {
      display: none;

      @media (min-width: 1024px) {
        display: block;
      }
    }

    &__excerpt {
      display: block;

      @media (min-width: 1024px) {
        display: none;
      }
    }
  }
`

export const AboutImage = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 300px;
`
